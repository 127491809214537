import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function wildfire(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/wildfire-case1.jpg'
  const thumbAltText = 'wildfire case study'
  const description = 'As Wildfire’s product grew and became more complex, Wildfire was looking to move away from a monolithic application and transition towards an app suite – a structured, more versatile platform to which you could easily add given plugins. Back in 2010, Wildfire partnered with us to help them achieve that end.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks developed the social media marketing application that allowed companies to monetize their engagements from a host of social platforms.'

  return (
    <Layout
      location={props.location}
      title='Wildfire'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <img src='/images/brands/wildfire-white-brand.png' alt='' />
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/wildfire-case-card-tall-bg.jpg' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Backend Development
                </li>
                <li className='case-meta__list-item'>
                  Frontend Development
                </li>
                <li className='case-meta__list-item'>
                  Process Consulting
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Social Media Marketing
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  4 years
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')} />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            Wildfire, a division of Google, is one of the world’s largest media marketing software providers. Their Social Marketing Suite is equipped with a set of tools for enterprises and agencies to reach target audiences; optimize landing pages; manage social network conversations; gather and make sense of social analytics; and track social performance of competitors.
          </p>
          <p>
            Wildfire’s leadership and digital innovation have not only caught the attention of over 21,000 paying customers worldwide, but also of President Obama who invited Founder Victoria Ransom to represent Silicon Valley at the signing of the JOBS Act.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            Working in close collaboration with the in-house team, we took the lead on the development of Wildfire’s plugins, all of which have an admin configuration site and a consumer-facing site. We restructured the plugin codebase, added social referral tracking to their plugins as well as Twitter, Google+ and other social platforms authentication support. Further, we concentrated on performance issues, boosting Wildfire’s ability to scale.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "WyeWorks has produced outstanding work for Wildfire. Throughout the entire process they have proven their excellence. The onboarding process was effective and painless and since then they have become an invaluable member of our outsourced teams. They have an attention to detail and a passion for quality that is rarely matched in the industry. They are also fun to work with and fantastic value for money. We would recommend them to anyone!"
              </div>
              <div className='quote-block__provider-title'>
                James Tucker
                <br />
                Director of Engineering
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/wildfire-case2.jpg' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            We were able to quickly and smoothly integrate our team with Wildfire’s as if we were another in-house developer. While we embraced their agile process and practices, we made contributions to improve communication flows; we brought transparency and openness to the dev process so Wildfire could easily track our progress and see how we worked. These efforts created a culture of collaboration that allowed us to develop the majority of plugins users interact with. And most importantly, it helped foster a dynamic and long-term relationship, one grounded in trust.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['aia', 'prisma']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
